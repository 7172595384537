import { graphql } from "gatsby"
import React from "react"
import ContactSales from "../components/common/ContactSales"
import Faqs from "../components/common/Faqs2"
import SEORevamp from "../components/common/SEO_Revamp"
import WorkPortfolio from "../components/common/WorkPortfolio"
import Banner from "../components/service-sections/Banner"
import Collaboration from "../components/service-sections/Collaboration"
import SubServices from "../components/service-sections/SubServices"
import Technologies from "../components/service-sections/Technologies"
import Workflow from "../components/service-sections/Workflow"
import MainLayout from "../layouts/MainLayout"

const ServiceTemplate = ({ data }) => {
  // const { seo, servicePage: service } = data.wpService
  // const { banner, subServices, collaboration, workflow, technologies, faqs } = service // prettier-ignore 

  const sec_1 = data?.strapiPage?.sections[0]
  const sec_2 = data?.strapiPage?.sections[1]
  const sec_3 = data?.strapiPage?.sections[2]
  const sec_4 = data?.strapiPage?.sections[3]
  const sec_5 = data?.strapiPage?.sections[4]
  const sec_6 = data?.strapiPage?.sections[5]

  return (
    <MainLayout bgChanged={false}>
      <Banner {...sec_1} breadCrumb={data?.strapiPage?.buttons} />
      <SubServices {...sec_2} />
      <Collaboration {...sec_3} />
      <WorkPortfolio />
      <Workflow {...sec_4} />
      <Technologies {...sec_5} />
      <Faqs strapiData={sec_6} />
      <ContactSales />
    </MainLayout>
  )
}

export const query = graphql`
  query getServicesData($id: String) {
    strapiPage(id: { eq: $id }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
      }
    }
  }
`

export default ServiceTemplate

export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  const sec_1 = data?.strapiPage?.sections[0]

  return (
    <SEORevamp
      title={metaTitle}
      description={metaDescription}
      image={sec_1?.secImages && sec_1?.secImages[0]?.url}
    />
  )
}
